import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import _ from "lodash";

import TeamMember1 from "../../../images/Team-details/team1.jpg";
import TeamMember1Tablet from "../../../images/Team-details/team1_tablet.jpg";
import TeamMember1Mobile from "../../../images/Team-details/team1_mobile.jpg";
import TeamMember2 from "../../../images/Team-details/team2.jpg";
import TeamMember2Tablet from "../../../images/Team-details/team2_tablet.jpg";
import TeamMember3 from "../../../images/Team-details/team3.jpg";
import TeamMember4 from "../../../images/Team-details/team4.jpg";
import { isMobile, isMobileTab } from "../../jsutility/index";
import {isTablet, isIPad13, isIOS13} from 'react-device-detect';
import { MEET_THE_TEAM_PAGE_URL } from "../../Common/site/constants";
import { useStaticQuery, graphql } from "gatsby"
import "./TeamMember.scss";
import logoBlack from "../../../images/logoblack.svg";
import { inViewOptions, containerVariants, delayItemsFromBottom } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";


const TeamMember = (props) => {

  const GET_TEAM_DETAIL = useStaticQuery(graphql`
    query {
         glstrapi {
            teams(sort:"createdAt:asc", limit: 10, where:{Publish:true}) {
                id
                Name
                Tile_Image {
                  url
                  alternativeText
                }
                URL
                Designation
                Email
                Phone
                Departments {
                  Title
                  URL
                }
                offices {
                  Name
                  URL
                }
            }
          }
  }`);

  const data = GET_TEAM_DETAIL?.glstrapi
  
  // const { loading, error, data } = useQuery(GET_TEAM_DETAIL);
  
  // if (loading) return (<><div className="gql-loading-screen"><p>Loading ...</p></div></>);

  // if (loading) return (
  //   <section className={"loader-wrapper"}>
  //     <div id="loader-wrapper">
  //       <div id="loader" class="new-loader">
  //         {/* <div className="new-loader-anime"></div> */}
  //         <img className="logo-white" src={logoBlack} className="loader-logo" alt="DB Roberts logo" />
  //       </div>
  //       {/* <div class="loader-section section-left"></div>
  //       <div class="loader-section section-right"></div> */}
  //     </div>
  //   </section>
  // );

  var TeamList = data?.teams;
  if (!_.isEmpty(props?.url)) {
    TeamList = data?.teams?.filter(list => list.URL != props.url);
  }
  const officeName = (item) => {
    if (_.toLower(item.Designation) === "branch manager" || _.toLower(item.Designation) === "area manager") {
      return _.join(_.map(item.offices, 'Name'), ' & ');
    }
  }


  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="team-section"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
            <Row>
              <Col xs="12">
                <div className="static-content-block">
                  <span className="sub-title">OTHER STAFF</span>
                  {/* <h2>
                    In bibendum porta nibh sit amet ullamcorper. Morbi id est at
                    erat posuere condimentum.
                  </h2> */}
                </div>
              </Col>
              <Col xs="12">
                <div className="team-slider">
                  {_.shuffle(TeamList).slice(0,4).map((item, index) => (
                    <motion.div
                      custom={index}
                      variants={delayItemsFromBottom}
                    >
                      <Link to={`${MEET_THE_TEAM_PAGE_URL.alias}/${item.URL}`} className="w-100">
                      <div key={index} className="team-member">
                        <div className="team-member-img img-zoom">
                          <picture>
                            <source
                              media="(min-width:992px)"
                              srcSet={item.Tile_Image.url}
                            />
                            <source
                              media="(min-width:768px)"
                              srcSet={item.Tile_Image.url}
                            />
                            <img className="" src={item.Tile_Image.url}  alt={`${item.Name} ${item.Designation} - DB Roberts`}  />
                          </picture>
                        </div>
                        <div className="team-member-info">
                          <div className="d-flex align-items-center team-member-hover">
                              {item.Name}
                            <i className="icon-arrow ml-auto"></i>
                          </div>

                          <span className="team-member-designation d-lg-none">
                          {officeName(item)} {item.Designation}
                          </span>
                          <a
                            href={`mailto: ${item.Email}`}
                            className="team-member-email"
                          >
                            {item.Email}
                          </a>
                          <a
                            href={`tel:+44${item.Phone}`}
                            className="team-member-number d-none d-lg-block"
                          >
                            {item.Phone}
                          </a>
                        </div>
                      </div>
                      </Link>
                    </motion.div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  );
};

export default TeamMember;
