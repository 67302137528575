import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react";
import { Button, Form, Container, Row, Col, Fade } from 'react-bootstrap';
import PlayVideo from "../../Play/CustomVideo";
import ModalTiny from "react-bootstrap/Modal"
import ReachUsForm from "../../Forms/react-us-form";
import ValuationForm from "../../Forms/valuation-form";
// Images
import MemberPicLarge from "../../../images/Team-details/team-member.jpg"
import MemberPicMedium from "../../../images/Team-details/team-member_t.jpg"
import MemberPicSmall from "../../../images/Team-details/team-member_m.jpg"
import './MemberDetails.scss';
import { MEET_THE_TEAM_PAGE_URL } from "../../Common/site/constants";
import _ from "lodash"

import { inViewOptions, containerVariants, fadeInFromBottom, contentItemStagger, contentItem } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const MemberDetails = (props) => {
  const [isPlaying, setPlaying] = useState(false);
  const [people, setPeople] = useState("");
  const [peopleNumber, setPeopleNumber] = useState("");

  const [modalContactformOpen, setContactformOpen] = React.useState(false);
  const closeContactformModal = () => {
    setContactformOpen(false);
  }

  const openContactformModal = (people, peopleNumber) => {
    setPeople(people)
    setPeopleNumber(peopleNumber)
    setContactformOpen(true);
  }

  const [modalValuationformOpen, setValuationformOpen] = React.useState(false);
  const closeValuationformModal = () => {
    setValuationformOpen(false);
  }

  const openValuationformModal = () => {
    setValuationformOpen(true);
  }
  const officeName = (item) => {
    if (_.toLower(item.Designation) === "branch manager" || _.toLower(item.Designation) === "area manager") {
      return _.join(_.map(item.offices, 'Name'), ' & ');
    }
  }

  return (
    <React.Fragment>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.div
            className="member-banner"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <Container>
              <Row>
                <Col lg="7">
                  <motion.div variants={contentItemStagger}>
                    <motion.div className="member-banner-content" variants={contentItem}>
                      <Link to={MEET_THE_TEAM_PAGE_URL.alias} className="back_btn"> {"<"} &nbsp;&nbsp;BACK TO MEET THE TEAM </Link>
                      {props.data && props.data.Name &&
                        <h1>{props.data.Name}</h1>
                      }

                      {props.data && props.data.Designation &&
                        <span className="member-designation d-none d-md-block">{officeName(props.data)} {props.data.Designation}</span>
                      }
                      <div className="d-none d-md-block member-contact">
                        {props.data && props.data.Email &&
                          <a href={`javascript:void(0)`} onClick={() => { openContactformModal(props.data.Name, props.data.Phone) }}>{props.data.Email}</a>
                        }
                        {props.data && props.data.Phone &&
                          <a href={`tel:+44${props.data.Phone}`}>{props.data.Phone}</a>
                        }
                      </div>
                    </motion.div>
                    <motion.div className="member-banner-btn" variants={contentItem}>
                      {props.data && props.data.Name &&
                        <Button className="btn btn-primary" onClick={() => { openContactformModal(props.data.Name, props.data.Phone) }}>Message {props.data.Name.split(" ")[0]} <i className="icon-arrow"></i></Button>
                      }
                      <Link to={`/property-valuation/home-visit-valuation`} className="btn btn-primary">Book a Valuation<i className="icon-arrow"></i></Link>
                    </motion.div>

                  </motion.div>
                </Col>
                <Col lg="5" className="img-zoom">

                  <motion.div className="member-banner-img" variants={fadeInFromBottom}>
                    {props.data && props.data.Full_Image &&
                      <picture>
                        <source media="(min-width:992px)" srcSet={props.data.Full_Image.url} />
                        <source media="(min-width:768px)" srcSet={props.data.Full_Image.url} />
                        <img className="" src={props.data.Full_Image.url} alt={props.data.Full_Image.alternativeText} />
                      </picture>
                    }

                    {
                      props.data && props.data.Video_URL &&
                      <PlayVideo url={props.data.Video_URL} icon="icon-play" class="play-btn play-btn-right" buttonName="Play" />
                    }
                  </motion.div>

                </Col>

              </Row>

            </Container>
          </motion.div>
        )}
      </InView>

      <ModalTiny size="lg" show={modalContactformOpen} onHide={closeContactformModal} className="modal-form">
        <ModalTiny.Header closeButton className="contact-close-btn">
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Contact {people}</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section"><ReachUsForm title={`Contact ${people}`} office={""} people_name={people} people_number={peopleNumber} email_temp_user="individual_contact_user" /></ModalTiny.Body>
      </ModalTiny>
      <ModalTiny size="lg" show={modalValuationformOpen} onHide={closeValuationformModal} className="modal-form">
        <ModalTiny.Header closeButton className="contact-close-btn">
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Book a Valuation</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section"><ValuationForm type="Book_a_valuation" title="Book a Valuation" /></ModalTiny.Body>
      </ModalTiny>
    </React.Fragment>

  )
}



export default MemberDetails