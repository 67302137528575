import { Link } from "@StarberryUtils";
import React,{ useEffect, useState } from "react";
import {Button, Form, Container, Row, Col,Fade} from 'react-bootstrap';
import  './ContentBlock.scss';
import HTMLReactParser from 'html-react-parser'; 

import { inViewOptions, containerVariants } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const ContentBlock = ( props ) => {

  return(
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="static-content"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
              <Row>
              <Col xs="12" lg="7">
                  <div className="static-content-block team-details-main-content">
                  {props.data && props.data.Name &&
                    <span className="sub-title">ABOUT {props.data.Name.split(" ")[0]}</span>
                  }

                  {props.data && props.data.About &&
                    HTMLReactParser(props.data.About.replace(/<br\s*\/?>/gi, ''))
                  }

                  </div>
              </Col>
              </Row>
              
          </Container>
        </motion.div>
      )}
    </InView>
    )
  }



export default ContentBlock